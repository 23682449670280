import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import SEO from 'src/components/SEO';
import { Loader } from 'src/components/shared';

const LibraryPage = () => {
    useEffect(() => {
        navigate('/workouts');
    }, []);

    return (
        <>
            <SEO title="Workout Library" />
            <div style={{ padding: '128px 40px', minHeight: '350px' }}>
                <Loader>
                    <h2 className="margin-bottom--large">Please hold. You are being Redirected.</h2>
                </Loader>
            </div>
        </>
    );
};

export default LibraryPage;
